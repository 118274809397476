import img0 from '../../assets/images/2016/objects/0.png';
import img1 from '../../assets/images/2016/objects/1.png';
import img2 from '../../assets/images/2016/objects/2.png';
import img3 from '../../assets/images/2016/objects/3.png';
import img4 from '../../assets/images/2016/objects/4.png';
import img5 from '../../assets/images/2016/objects/5.png';
import img6 from '../../assets/images/2016/objects/6.png';
import img7 from '../../assets/images/2016/objects/7.png';
import img8 from '../../assets/images/2016/objects/8.png';

function Objects(props) {
  return (
    <div className="App">
      <img src={img0} className="image-style-1" alt="0" />
      <img src={img1} className="image-style-1" alt="1" />
      <img src={img2} className="image-style-1" alt="2" />
      <img src={img3} className="image-style-1" alt="3" />
      <img src={img4} className="image-style-1" alt="4" />
      <img src={img5} className="image-style-1" alt="5" />
      <img src={img6} className="image-style-1" alt="6" />
      <img src={img7} className="image-style-1" alt="7" />
      <img src={img8} className="image-style-1" alt="8" />
    </div>
  );
}

export default Objects;