import logo from '../assets/images/logo.svg';
import '../assets/styles/App.css';

function WIP(props) {
  return (
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <p>
        --- UNDER CONSTRUCTION! ---
      </p>
    </header>
  )
}

export default WIP;
