function LuxAeterna(props) {
  return (
    <div className="App">
      <p><iframe width="720" height="405" src="https://www.youtube.com/embed/t8Yfo3rCo3g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>
      <p><strong><em>LUX AETERNA (MV)</em></strong><br />
        2014<br />
        1m47s</p>
    </div>
  );
}

export default LuxAeterna;